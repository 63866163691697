@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: 'SansSerifFLF';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "SansSerifFLF";
  src: local("SansSerifFLF"),
  url("./font/sansserifflf-cufonfonts/SansSerifBookFLF.otf") format("truetype");
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  height: 100%;
}

.full-height {
  height: 100%;
}

.page-header {
  background-color: #FFFFFF;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
}
